import { createApp } from "vue";
import App from "./App.vue";
import "./assets/main.css";
import router from "./router";
import ElemenetUi from "element-plus";
import "element-plus/dist/index.css";
import vant from "vant";
// 2. 引入组件样式
import "vant/lib/index.css";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import "animate.css";
import { gsap } from "gsap";
/* eslint-disable */
const app = createApp(App);
import { createPinia } from "pinia";
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.config.globalProperties.$gsap = gsap;
app.use(router);
app.use(ElemenetUi);
app.use(vant);
app.use(pinia);
app.mount("#app");
