import { createRouter, createWebHistory } from "vue-router";
import { useIndexStore } from "@/stores/indexStore";

const routes = [
  {
    path: "/",
    component: () => import("../views/NavegationView.vue"),
  },

  {
    path: "/creditView",
    name: "CreditView",
    component: () => import("../views/CreditView.vue"),
  },
  // {
  //   path: "/navegationView",
  //   name: "NavegationView",
  //   component: () => import("../views/NavegationView.vue"),
  // },
  {
    path: "/qrcodeView",
    name: "QrcodeView",
    component: () => import("../views/QrcodeView.vue"),
  },
  {
    path: "/loginView",
    name: "LoginView",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/shopList",
    name: "ShopList",
    component: () => import("../views/ShopList.vue"),
  },
  {
    path: "/billList",
    name: "BillList",
    component: () => import("../views/BillList.vue"),
  },
  {
    path: "/withdrawalRecord",
    name: "WithdrawalRecord",
    component: () => import("../views/withdrawalRecord.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const whileList = ["/loginView"];
router.beforeEach((to, from, next) => {
  const useStore = useIndexStore();
  let flag = useStore.stateCode == 0 ? useStore.token : false;
  console.log("flag", flag);
  useStore.isIntercept = false;
  if (whileList.includes(to.path) || useStore.token) {
    console.log("path:", to.path);
    console.log("from:", from);
    console.log("执行next()");
    next();
  } else {
    console.log("执行next(/loginView)");
    next("/loginView");
  }
});

export default router;
