import { defineStore } from "pinia";
export const useIndexStore = defineStore("indexStore", {
  state: () => ({
    inputPassword: "",
    // password: JSON.parse(localStorage.getItem("bill")) || "123456",
    password: "",
    token: "",
    isIntercept: false,
    stateCode: 0,
    baseScr: "https://sea.im/api",
    qrcodeStr: "",
    uid: "",
  }),
  persist: true,
  getters: {},
  actions: {},
});
